import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { HTMLContent } from './Content'
import github from '../img/social/github.svg'


const ProjectRoll = ({ data }) => {

	const { edges: projects } = data.allMarkdownRemark;

	const [filter, setFilter] = useState('All');
	const [modalId, setModalId] = useState(null);
	const [pictureId, setPictureId] = useState(null);
	return (
		<div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
			{/* Filters */}
			<div className="project-filter">
				<button
					className={`project-filter-item ${(filter === 'All') ? "active" : ""}`}
					onClick={() => setFilter('All')}
				>
					All
				</button>
				<button
					className={`project-filter-item ${(filter === 'Production') ? "active" : ""}`}
					onClick={() => setFilter('Production')}
				>
					Production
				</button>
				<button
					className={`project-filter-item ${(filter === 'Academic') ? "active" : ""}`}
					onClick={() => setFilter('Academic')}
				>
					Academic
				</button>
			</div>

			{/* Project list */}
			<div className="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-8-widescreen is-2-fullhd project-body">
				{/* List of Cards */}
				{projects.map(({ node: project }, projectIndex) => (project.frontmatter.filters.includes(filter) &&
					<div key={projectIndex} className="column is-variable is-12-mobile is-6-tablet is-4-widescreen item">
						{/* article used instead of button */}
						{/* eslint-disable-next-line */}
						<article className="item-inside" onClick={() => setModalId(projectIndex)} onKeyDown={() => setModalId(projectIndex)}>

							<div className="header-body">
								<div className="image-body">
									<img alt="cover" src={project.frontmatter.cover} />
								</div>
								<div className="text-body">
									<span role="button" tabIndex={0} className="header" onClick={() => setModalId(projectIndex)} onKeyDown={() => setModalId(projectIndex)}>
										{project.frontmatter.title}
									</span>
									<p className="time">
										{project.frontmatter.period}
									</p>
									<div className="tags-body">
										{project.frontmatter.tags.map((item, tagIndex) => (
											<span key={tagIndex} className="tag">{item}</span>
										))}
									</div>
									<p className="desc">
										{project.frontmatter.description}
									</p>
								</div>
							</div>

						</article>
					</div>
				))}

				{/* List of Modals */}

				{projects.map(({ node: project }, projectIndex) => (
					<div className={`modal ${modalId === projectIndex ? 'is-active' : ''}`}>
						<div
							role="button"
							tabIndex={0}
							aria-label="modal-background"
							className="modal-background"
							onClick={() => setModalId(null)}
							onKeyDown={() => setModalId(null)}
						></div>

						<div className="modal-card">
							<header className="head">
								<p className="title">
									{project.frontmatter.title}
								</p>
								<button className="delete" aria-label="delete" onClick={() => setModalId(null)}></button>
							</header>

							<section className="body">
								<div className="cover-div">
									<img src={project.frontmatter.cover} alt="" />
								</div>

								<p className="desc">
									<HTMLContent content={project.html} />
								</p>

								<div className="bottom">

									<div className="tags-body">
										{project.frontmatter.tags.map((item, tagIndex) => (
											<span className="tag" key={tagIndex}>{item}</span>
										))}
									</div>
									<p className="time">
										{project.frontmatter.period}
									</p>
								</div>
								{/* COLUMN WISE */}
								{/* <div class="columns is-multiline">
								<div class="column is-4"><a href="#"><img src="img/light-bw.jpg" alt=""/></a></div>
								<div class="column is-4"><a href="#"><img src="img/light-bw.jpg" alt=""/></a></div>
								<div class="column is-4"><a href="#"><img src="img/light-bw.jpg" alt=""/></a></div>
							</div> */}

								{/* MODAL WITH BLUR */}

								<div className='images'>
									<div className="image-box">
										{project.frontmatter.images.map((item, picIndex) => (
											// eslint-disable-next-line
											<img
												className="modal-button"
												alt="project"
												src={item}
												onClick={() => setPictureId(picIndex)}
												onKeyDown={() => setPictureId(picIndex)}
											/>
										))}
									</div>

									{project.frontmatter.images.map((item, picIndex) => (
										<div>
											<div key={picIndex} class={`modal modal-fx-fadeInScale ${modalId === projectIndex && pictureId === picIndex ? 'is-active' : ''}`}>
												<div role="button" tabIndex={picIndex} class="modal-background" onClick={() => setPictureId(null)} onKeyDown={() => setPictureId(null)}>
												</div>
												<div role="button" tabIndex={picIndex} class="lightbox-content" onClick={() => setPictureId(null)} onKeyDown={() => setPictureId(null)}>
													<img className="lightbox-image" alt="project" src={item} />
												</div>
												{/* <button class="modal-close is-large" onClick={() => setPictureId(null)}></button> */}
											</div>
										</div>
									))}
								</div>
							</section>

							<footer className="modal-card-foot">
								{project.frontmatter.source && (
									<a
										href={project.frontmatter.source}
										target="_blank"
										rel="noreferrer"
										className="button is-success"
									>
										Source
									</a>
								)}
								{project.frontmatter.demo && (
									<a
										href={project.frontmatter.demo}
										target="_blank"
										rel="noreferrer"
										className="button is-info"
									>
										Demo
									</a>
								)}
							</footer>
						</div>
					</div>
				))}

			</div>

			<a href="https://github.com/Tahmeed156" target="_blank" rel="noreferrer" class="github-button">
				<b>See more projects on Github</b>
				<img
					src={github}
					alt="Github"
				/>
			</a>
		</div>
	)

}

ProjectRoll.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array,
		}),
	}),
}

export default () => (
	<StaticQuery
		query={graphql`
      query ProjectRollQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "project" } } }
        ) {
          edges {
            node {
              id
							html
              frontmatter {
								title
								period
								tags
								description
								images
								cover
								filters
								source
								demo
              }
            }
          }
        }
      }
    `}
		render={(data, count) => <ProjectRoll data={data} count={count} />}
	/>
)
