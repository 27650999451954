import React from 'react'

import Layout from '../../components/Layout'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import ProjectRoll from '../../components/ProjectRoll'
import TechStack from '../../components/TechStack'


const ProjectIndexPage = () => {
  return (
    <Layout>
      <Navbar page='projects' />
      <div className="projects container content">
        <h1 className="page-header">
          ~ My Work ~
        </h1>

        {/* Summary */}
        {/* <div className="summary-div columns is-centered">
          <p className="summary column is-two-thirds">
            TODO: Write Somethin
          </p>
        </div> */}

        <TechStack />

        <h2>Projects</h2>
        <ProjectRoll />

      </div>
      <Footer />
    </Layout>
  )
}

export default ProjectIndexPage;